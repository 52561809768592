import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SliceZone from "../components/slice-zone"
import { withPreview } from "gatsby-source-prismic"

export const query = graphql`
  query LandingPageQuery($uid: String, $lang: String) {
    prismicLandingPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      alternate_languages {
        uid
        id
        isBroken
        link_type
        raw
        size
        slug
        tags
        target
        url
        type
        lang
      }
      data {
        meta_description
        meta_keywords
        title_meta
        reduced_layout
        image_meta {
          url
        }
        body {
          ... on PrismicLandingPageBodyHeroSectionWithSignupForm {
            id
            slice_type
            primary {
              has_waves_cutout
              form_title
              anchor_id
              recruiter_hero_subtitle: subtitle
              recruiter_hero_title: title
              background_image {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyHeroSection {
            id
            slice_type
            primary {
              link_text
              hero_subtitle: subtitle
              theme
              has_waves_cutout
              hero_title: title
              button_text
              link {
                url
                type
                target
              }
              background_image {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyPartnersCarousel {
            id
            slice_type
            primary {
              title_is_bold
              partners_carousel_title: title
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyTestimonialCarousel {
            id
            slice_type
            primary {
              testimonial_carousel_title: title
            }
            items {
              name
              role
              testimonial_carousel_text {
                html
                raw
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 80) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyInfoWithIcons {
            id
            slice_type
            primary {
              info_with_icons_subtitle: subtitle
              info_with_icons_title: title
            }
            items {
              info_body {
                html
                raw
                text
              }
              link_text
              info_with_icons_item_title: title
              link_url {
                url
                type
                target
              }
              button_icon {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyHomepageIntegrations {
            id
            slice_type
            primary {
              integrations_title: title
              integrations_subtitle: subtitle
              button_text
              button_link {
                url
                type
                target
              }
            }
            items {
              logo {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyPricingCards {
            id
            slice_type
            slice_label
            primary {
              pricing_cards_title: title
              button_text
              button_url {
                url
                type
                target
              }
            }
            items {
              plan_name
              plan_subtitle
              price_line {
                html
                raw
                text
              }
              plan_body {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyNetwork {
            id
            slice_type
            primary {
              sector1
              sector2
              sector3
              network_title: title
              image {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyFaqVideo {
            id
            slice_type
            slice_label
            primary {
              button_text
              faq_video_title: title
              faq_video_subtitle: subtitle
              button_url {
                url
                type
                target
              }
            }
            items {
              tab_title
              video_number
            }
          }
          ... on PrismicLandingPageBodyFeatures {
            id
            slice_type
            primary {
              features_title: title
              button_text
              button_link {
                url
                type
                target
              }
              body1 {
                html
                raw
                text
              }
              image {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 80) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyContentBlog {
            id
            slice_type
            slice_label
            primary {
              content_blog {
                html
                raw
                text
              }
              text_color
            }
          }
          ... on PrismicLandingPageBodyBubbles {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              subtitle_bubbles
              title {
                html
                raw
                text
              }
            }
            items {
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyScreenshots {
            id
            slice_type
            slice_label
            items {
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyTiledContent {
            id
            slice_type
            slice_label
            items {
              cta_button
              cta_url
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              subtitle {
                html
                raw
                text
              }
              anchor_id
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodySingleColumn {
            id
            slice_type
            slice_label
            items {
              text {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyPool {
            id
            slice_type
            slice_label
            items {
              image {
                url
              }
              linked
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyPlans {
            id
            slice_type
            slice_label
            items {
              benefits {
                html
                raw
                text
              }
              frequency
              price
              subtitle
              title {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                text
                raw
                html
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyContactUs {
            id
            slice_type
            slice_label
            primary {
              anchor_id
            }
          }
          ... on PrismicLandingPageBodyCta {
            id
            slice_type
            slice_label
            primary {
              cta_centered {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyPageHead {
            id
            slice_type
            slice_label
            primary {
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyLogoCarousel {
            id
            slice_type
            slice_label
            items {
              logo {
                url
              }
            }
            primary {
              anchor_id
              subtitle_carousel
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyCustomTiled {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            items {
              text_order
              button_url
              button_text
              text {
                raw
              }
              title {
                raw
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicLandingPageBodyInfoBox {
            id
            slice_type
            slice_label
            primary {
              description {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyOverviewSection {
            id
            slice_type
            slice_label
            items {
              section_anchor_id
              section_title
            }
            primary {
              anchor_id
              overview_title
            }
          }
          ... on PrismicLandingPageBodyFlowChart {
            id
            slice_type
            slice_label
            items {
              flow
            }
            primary {
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyVerticalSpace {
            id
            slice_type
            slice_label
            primary {
              size
            }
          }
          ... on PrismicLandingPageBodyLinkBox {
            id
            slice_type
            slice_label
            items {
              link_url {
                url
                link_type
                slug
              }
              link_title
            }
            primary {
              link_box_title
            }
          }
          ... on PrismicLandingPageBodyProcess {
            id
            slice_type
            primary {
              process_title: title
            }
            items {
              process_item_title: title
              image {
                url
              }
              process_body {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyQuoteBox {
            id
            slice_type
            slice_label
            primary {
              quote_text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyHero {
            id
            primary {
              anchor_id
              background_image {
                url
              }
              button
              cta_url
              hero_image {
                url
              }
              has_waves_cutout
              subtitle_hero
              text_color
              title {
                html
                raw
                text
              }
              text {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyBubbles {
            id
            slice_label
            slice_type
            primary {
              anchor_id
              subtitle_bubbles
              title {
                html
                raw
                text
              }
            }
            items {
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyScreenshots {
            id
            items {
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyTiledContent {
            id
            items {
              cta_button
              cta_url
              image {
                url
              }
              text {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              subtitle {
                html
                raw
                text
              }
              anchor_id
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodySingleColumn {
            id
            items {
              text {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyPool {
            id
            items {
              image {
                url
              }
              linked
            }
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyPlans {
            id
            items {
              benefits {
                html
                raw
                text
              }
              frequency
              price
              subtitle
              title {
                html
                raw
                text
              }
            }
            primary {
              anchor_id
              subtitle {
                text
                raw
                html
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyContactUs {
            id
            primary {
              anchor_id
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyCta {
            id
            primary {
              cta_centered {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyPageHead {
            id
            primary {
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyIntegrations {
            id
            items {
              link
              link_text
              logo {
                url
              }
              title {
                html
                raw
                text
              }
            }
            primary {
              title {
                text
                raw
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyTestimonials {
            id
            items {
              avatar {
                url
              }
              text {
                html
                raw
                text
              }
              name
              position
            }
            primary {
              subtitle_testi
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyLogoCarousel {
            id
            items {
              logo {
                url
              }
            }
            primary {
              anchor_id
              subtitle_carousel
              title {
                html
                raw
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicLandingPageBodyCustomTiled {
            id
            primary {
              anchor_id
              subtitle {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
            items {
              text_order
              button_url
              text {
                raw
              }
              title {
                raw
              }
              image {
                url
                alt
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicLandingPageBodyInfoBox {
            id
            slice_type
            primary {
              description {
                html
                raw
                text
              }
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyOverviewSection {
            id
            items {
              section_anchor_id
              section_title
            }
            primary {
              anchor_id
            }
            slice_type
          }
          ... on PrismicLandingPageBodyFlowChart {
            id
            items {
              flow
            }
            slice_type
            primary {
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyVerticalSpace {
            primary {
              size
            }
            slice_type
          }
          ... on PrismicLandingPageBodyProcess {
            id
            slice_type
            primary {
              process_title: title
            }
            items {
              process_item_title: title
              image {
                url
              }
              process_body {
                html
                raw
                text
              }
            }
          }
          ... on PrismicLandingPageBodyLinkBox {
            id
            slice_type
            items {
              link_url {
                url
                link_type
                slug
              }
              link_title
            }
            primary {
              link_box_title
            }
          }
          ... on PrismicLandingPageBodyQuoteBox {
            id
            primary {
              quote_text {
                html
                raw
                text
              }
            }
            slice_type
          }
          ... on PrismicLandingPageBodyDivider {
            id
            slice_type
          }
          ... on PrismicLandingPageBodyHeroSectionReducedForm {
            id
            slice_type
            primary {
              reduced_form_subtitle: subtitle
              reduced_form_title: title
              source
              anchor_id
              image {
                url
              }
            }
          }
          ... on PrismicLandingPageBodyContinueReading {
            id
            slice_type
            primary {
              continue_reading_title: title
              continue_reading_custom_tag {
                url
                type
                lang
                uid
              }
            }
          }
        }
      }
    }
  }
`

const RenderBody = ({ body, locale, path }) => {
  if (!body) {
    return null
  }
  return (
    <Fragment>
      <SliceZone allSlices={body} locale={locale} path={path} />
    </Fragment>
  )
}

const LandingPageTemplate = ({ data, pageContext, uri, location, ...rest }) => {
  const { lang: locale } = pageContext
  const doc = data?.prismicLandingPage.data
  if (!doc) return null
  const {
    body,
    title_meta,
    meta_keywords,
    meta_description,
    image_meta,
    reduced_layout,
  } = doc
  return (
    <Layout
      metadata={{
        title: title_meta,
        description: meta_description,
        keywords: meta_keywords,
        image: image_meta?.url,
      }}
      mainPage="companies"
      locale={locale}
      path={location && location.pathname}
      alternateLanguages={data.prismicLandingPage.alternate_languages}
      reducedLayout={reduced_layout}
      isLandingPage
    >
      <RenderBody
        body={body}
        locale={locale}
        path={location && location.pathname}
      />
    </Layout>
  )
}

export default withPreview(LandingPageTemplate)
